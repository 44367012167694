import React from 'react';
import { Video } from 'components';
import * as styles from './video-clipped.module.scss';

const VideoClipped = ({ video, className }) => (
  <div className={`${styles.container} ${className || ''}`}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <clipPath id="logo-mask">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.396 -0.000186786C387.387 0.0247757 382.621 2.04366 379.275 5.55402C379.275 5.55402 330.401 56.3392 267.798 130.571V704.956H142.016V291.782C112.081 333.921 83.8121 377.43 60.4722 419.907C24.7039 485 0 547.444 0 601.247C0 805.306 175.827 970.998 392.396 970.998C449.848 970.998 504.446 959.338 553.663 938.394L301.81 379.088H434.58L618.597 796.858L754.513 480.278C745.879 460.54 735.732 440.354 724.495 419.907C688.727 354.817 641.389 287.303 594.219 226.011C499.873 103.416 405.655 5.55402 405.655 5.55402C402.278 2.01246 397.453 -0.012668 392.396 -0.000186786Z"
            fill="black"
          />
        </clipPath>
      </g>
    </svg>
    <div className={styles.videoContainer} style={{ WebkitClipPath: 'url(#logo-mask)', clipPath: 'url(#logo-mask)' }}>
      <Video src={video} id="header-video" />
    </div>
  </div>
);

export default VideoClipped;
