import React, { Fragment } from 'react';
import { Section, Text, Form as FormComp, Link, OnVisible } from 'components';
import FilledLogo from 'images/logo-dark.inline.svg';
import * as styles from './styles.module.scss';

const Form = (props) => {
  const { data } = props;
  const { primary } = data;
  const { tagline, title } = primary;

  const physicianFields = [
    {
      type: 'text',
      name: 'name',
      label: 'Physician name',
      defaultValue: '',
      validation: { required: true },
      validationMessage: "Please enter your physician's name",
      className: styles.splitLeft,
    },
    {
      type: 'text',
      name: 'practice',
      label: 'Physician practice',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter the practice name',
      className: styles.splitRight,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Practice address',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter the practice address',
      className: styles.splitLeft,
    },
    {
      type: 'text',
      name: 'edi',
      label: 'EDI',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter the EDI',
      className: styles.splitRight,
    },
    {
      type: 'tel',
      name: 'phone',
      label: 'Practice phone',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter the practice phone number',
      className: styles.splitLeft,
    },
    {
      type: 'email',
      name: 'email',
      label: 'Physician email',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
      className: styles.splitRight,
    },
  ];

  const patientFields = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First name',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
      className: styles.splitLeft,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last name',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your last name',
      className: styles.splitRight,
    },
    {
      type: 'date',
      name: 'birthDate',
      label: 'Birth date',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter the practice address',
      className: styles.splitLeft,
    },
    {
      type: 'text',
      name: 'nhi',
      label: 'NHI',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your NHI',
      className: styles.splitRight,
    },
    {
      type: 'email',
      name: 'email',
      label: 'Patient email',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
      className: styles.splitLeft,
    },
    {
      type: 'tel',
      name: 'patientPhone',
      label: 'Patient phone',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter the practice phone number',
      className: styles.splitRight,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Patient address',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your address',
      className: styles.splitLeft,
    },
  ];

  const reasonsFields = [
    {
      type: 'checkbox',
      name: 'pain',
      label: 'Chronic Pain',
      defaultValue: '',
      className: styles.splitLeft,
      options: [
        'Arthritis',
        'Inflammatory Polyanthropy',
        'Post Operative/Traumatic',
        'Fibromyalgia',
        'Neuropathic',
        'Malignancy',
        'Other',
      ],
      otherBox: {
        type: 'text',
        name: 'painOther',
        placeholder: 'Chronic pain (Other) Please speicify',
        defaultValue: '',
        validation: { required: false },
      },
    },
    {
      type: 'checkbox',
      name: 'gastrointestinal',
      label: 'Gastrointestinal',
      defaultValue: '',
      className: styles.splitRight,
      options: ['Chron’s Disease', 'Ulcerative Colitis', 'Irritable Bowel Syndrome', 'Other'],
      otherBox: {
        type: 'text',
        name: 'gastrointestinalOther',
        placeholder: 'Gastrointestinal (Other) Please speicify',
        defaultValue: '',
        validation: { required: false },
      },
    },

    {
      type: 'checkbox',
      name: 'mentalHealth',
      label: 'Mental health',
      defaultValue: '',
      className: styles.splitLeft,
      options: ['Anxiety', 'Depression', 'PTSD', 'Eating Disorder', 'ADHD', 'Other'],
      otherBox: {
        type: 'text',
        name: 'mentalHealthOther',
        placeholder: 'Mental health (Other) Please speicify',
        defaultValue: '',
        validation: { required: false },
      },
    },

    {
      type: 'checkbox',
      name: 'neurological',
      label: 'Neurological',
      defaultValue: '',
      className: styles.splitRight,
      options: [
        'Cognitive impairment',
        'Seizure disorder',
        'Migraines/Headaches',
        'Multiple Sclerosis',
        'Parkinson’s Disease’',
        'Other',
      ],
      otherBox: {
        type: 'text',
        name: 'neurologicalOther',
        placeholder: 'Neurological (Other) Please speicify',
        defaultValue: '',
        validation: { required: false },
      },
    },

    {
      type: 'checkbox',
      name: 'ailments',
      label: 'Other Ailments',
      defaultValue: '',
      options: [
        'Insomnia',
        'Sleep disordered breathing',
        'Appetite Stimulation',
        'HIV/AIDS',
        ' Recreational User Consultation for Harm Prevention',
      ],
    },
  ];

  const medicineFields = [
    {
      type: 'radio',
      name: 'anticoagulants',
      label: ' Currently taking Anticoagulants',
      validation: { required: true },
      validationMessage: 'Please select yes or no',
      options: ['Yes', 'No'],
      className: styles.splitLeft,
    },
    {
      type: 'radio',
      name: 'pregnancy',
      label: 'Pregnancy or Family Planning',
      validation: { required: true },
      validationMessage: 'Please select yes or no',
      options: ['Yes', 'No'],
      className: styles.splitRight,
    },
    {
      type: 'radio',
      name: 'abuse',
      label: 'History of Substance Abuse or Addiction',
      validation: { required: true },
      validationMessage: 'Please select yes or no',
      options: ['Yes', 'No'],
      className: styles.splitLeft,
    },
    {
      type: 'radio',
      name: 'psychotic',
      label: 'History of Psychotic Illness',
      validation: { required: true },
      validationMessage: 'Please select yes or no',
      options: ['Yes', 'No'],
      className: styles.splitRight,
    },
  ];

  const sections = [
    { title: 'Physician Information', fields: physicianFields },
    { title: 'Patient Information', fields: patientFields },
    { title: 'Reason for referral', fields: reasonsFields },
    { title: 'Current Medications', fields: medicineFields },
  ];

  return (
    <Section
      className={`referral-form ${styles.section}`}
      containerClassName={styles.container}
      sliceName="Form"
      gradientBg
    >
      <FilledLogo className={styles.logo} />
      {tagline?.text && <div dangerouslySetInnerHTML={{ __html: tagline.html }} className={styles.tagline} />}
      {title?.text && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
      <FormComp sections={sections} formSparkId="nzaaIXP5" />
    </Section>
  );
};
export default Form;
