import React, { useState } from 'react';
import { Section, Text, Image, Carousel } from 'components';
import Stars from 'images/icons/green-stars.inline.svg';
import LeftArrow from 'images/icons/white-arrow-left.inline.svg';
import RightArrow from 'images/icons/white-arrow-right.inline.svg';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './styles.module.scss';

const Testimonials = (props) => {
  const { data } = props;
  const { primary } = data;
  const { tagline, title } = primary;
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  const queryData = useStaticQuery(graphql`
    query testimonialsQuery {
      ...settingsFragment
    }
  `);

  const {
    prismicSettings: {
      data: { testimonials },
    },
  } = queryData;

  const settings = {
    dots: false,
    infinite: true,
    cssEase: 'linear',
    arrows: false,
    speed: 300,
    slidesToScroll: 1,
    draggable: true,
    variableWidth: true,
    adaptiveHeight: false,
  };

  const [slick, setSlick] = useState(null);

  const slickObj = slick?.current;

  // custom button handlers
  const handleLeftClick = () => {
    slickObj.slickPrev();
  };
  const handleRightClick = () => {
    slickObj.slickNext();
  };

  return (
    <Section className={styles.testimonials} gradientBg>
      <div className={styles.section} containerClassName={styles.topContainer} gradientBg sliceName="Testimonials">
        <div className={styles.textContainer}>
          <div>
            {taglineHtml && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
            {titleHtml && <Text html={titleHtml} className={styles.title} h2 />}
          </div>
          <div className={styles.buttons}>
            <LeftArrow onClick={handleLeftClick} className={styles.carouselButton} />
            <RightArrow onClick={handleRightClick} className={styles.carouselButton} />
          </div>
        </div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.container}>
          <div className={styles.carouselContainer}>
            <Carousel settings={settings} sliderRef={setSlick}>
              {testimonials.map((item) => {
                const { content, image, name, role, title: reviewTitle } = item;
                return (
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <Stars className={styles.stars} />
                      {reviewTitle && <Text html={reviewTitle.html} className={styles.reviewTitle} />}
                      {content && <Text html={content.html} p1 className={styles.content} />}
                    </div>
                    <div className={styles.authorContainer}>
                      <Image image={image} className={styles.image} />
                      <div>
                        {name && <Text html={name.html} h4 className={styles.name} />}
                        {role && <Text html={role.html} p1 className={styles.role} />}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default Testimonials;
