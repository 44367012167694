// extracted by mini-css-extract-plugin
export var bio = "styles-module--bio--588f9";
export var bioContainer = "styles-module--bioContainer--d5e8b";
export var blueBg = "styles-module--blueBg--d99fe";
export var button = "styles-module--button--0c3c0";
export var card = "styles-module--card--39c3c";
export var container = "styles-module--container--861a9";
export var credentials = "styles-module--credentials--8da7a";
export var custardBg = "styles-module--custardBg--f8f2f";
export var grid = "styles-module--grid--4c434";
export var image = "styles-module--image--cad51";
export var logo = "styles-module--logo--a5a2c";
export var modal = "styles-module--modal--fa977";
export var modalContent = "styles-module--modalContent--ac19f";
export var name = "styles-module--name--25daa";
export var role = "styles-module--role--982f2";
export var section = "styles-module--section--5cca1";
export var title = "styles-module--title--2e8da";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--e263b";