import React from 'react';
import { Link } from 'components';
import classNames from '../../helpers/classNames';

import * as styles from './styles.module.scss';

const Arrow = ({ className }) => (
  <svg className={className} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.292787 0.490052C0.105316 0.677579 0 0.931887 0 1.19705C0 1.46221 0.105316 1.71652 0.292787 1.90405L5.24279 6.85405L0.292787 11.804C0.110629 11.9926 0.00983372 12.2452 0.0121121 12.5074C0.0143906 12.7696 0.11956 13.0205 0.304968 13.2059C0.490376 13.3913 0.741188 13.4964 1.00339 13.4987C1.26558 13.501 1.51818 13.4002 1.70679 13.218L7.36379 7.56104C7.55126 7.37352 7.65657 7.11921 7.65657 6.85405C7.65657 6.58888 7.55126 6.33457 7.36379 6.14705L1.70679 0.490052C1.51926 0.302581 1.26495 0.197266 0.999786 0.197266C0.734622 0.197266 0.480314 0.302581 0.292787 0.490052Z"
      fill="#FFFEFC"
    />
  </svg>
);

const Button = (props) => {
  const { to, title, children, theme = 'primary', outline, className } = props;
  return (
    <Link
      className={classNames(className, styles.button, styles[theme], outline && styles.outline)}
      to={to}
      title={title || ''}
    >
      {children}
      <Arrow className={styles.arrow} />
    </Link>
  );
};

export default Button;
