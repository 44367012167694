import React from 'react';
import { Section, Text, OnVisible } from 'components';
import Logo from 'images/logo-cream.inline.svg';
import FilledLogo from 'images/logo-dark.inline.svg';
import Button from 'components/Button';
import * as styles from './styles.module.scss';

const CenteredCta = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    background_color: bgColor,
    tagline,
    content,
    exclude_logo: excludeLogo,
  } = primary;

  const widthVariant = title?.text?.length <= 44 ? styles.shortContainer : '';
  const bgColour = bgColor === 'Custard' ? styles.custardBg : '';
  const logo = bgColor === 'Custard' ? <FilledLogo className={styles.logo} /> : <Logo className={styles.logo} />;

  return (
    <Section
      className={`${styles.section} ${widthVariant} ${bgColour}`}
      containerClassName={styles.container}
      sliceName="CenteredCta"
      gradientBg={bgColor === 'Custard'}
    >
      <div className={styles.textContainer}>
        {!excludeLogo && (
          <OnVisible className={styles.logoContainer} visibleClassName={styles.visLogoContainer}>
            {logo}
          </OnVisible>
        )}
        {tagline?.text && <div dangerouslySetInnerHTML={{ __html: tagline?.html }} className={styles.tagline} />}
        {title?.text && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
        {content?.text && <Text html={content.html} className={styles.content} p2 />}
      </div>
      {ctaLink?.url && ctaText?.text && (
        <OnVisible className={styles.buttonContainer} visibleClassName={styles.visButtonContainer}>
          {/* <Link to={ctaLink.url} className={`button secondary ${styles.button}`}>
            {ctaText.text}
          </Link> */}
          <Button theme={bgColor === 'Custard' ? 'primary' : 'tertiary'} to={ctaLink.url}>
            {ctaText.text}
          </Button>
        </OnVisible>
      )}
    </Section>
  );
};
export default CenteredCta;
