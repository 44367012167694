// extracted by mini-css-extract-plugin
export var button = "styles-module--button--0774e";
export var buttonContainer = "styles-module--buttonContainer--5ae9a";
export var content = "styles-module--content--6cb61";
export var custardBg = "styles-module--custardBg--3aa14";
export var logo = "styles-module--logo--db5b1";
export var logoContainer = "styles-module--logoContainer--720f0";
export var section = "styles-module--section--804fd";
export var shortContainer = "styles-module--shortContainer--37f89";
export var tagline = "styles-module--tagline--c5f15";
export var textContainer = "styles-module--textContainer--e15fa";
export var title = "styles-module--title--4f3ca";
export var visButtonContainer = "styles-module--visButtonContainer--e29c1";
export var visLogoContainer = "styles-module--visLogoContainer--e85b9";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--88ae6";