// extracted by mini-css-extract-plugin
export var bio = "styles-module--bio--327e4";
export var bioContainer = "styles-module--bioContainer--59280";
export var blueBg = "styles-module--blueBg--770da";
export var button = "styles-module--button--c65ff";
export var card = "styles-module--card--2c641";
export var container = "styles-module--container--14025";
export var credentials = "styles-module--credentials--e8766";
export var cta = "styles-module--cta--4dae7";
export var custardBg = "styles-module--custardBg--a36fe";
export var grid = "styles-module--grid--950b6";
export var image = "styles-module--image--4d022";
export var logo = "styles-module--logo--fdfbc";
export var modal = "styles-module--modal--2c512";
export var modalContent = "styles-module--modalContent--f3f54";
export var name = "styles-module--name--e1510";
export var role = "styles-module--role--e8142";
export var section = "styles-module--section--36d66";
export var textContainer = "styles-module--textContainer--ce6bf";
export var title = "styles-module--title--a0a8a";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--f0f0d";