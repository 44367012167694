import React, { useState, useEffect, Fragment } from 'react';
import { Section, Text, Link, Image, OnVisible, Modal } from 'components';
import FilledLogo from 'images/logo-dark.inline.svg';
import * as styles from './styles.module.scss';
import Button from '../../components/Button';

const ModalTeamGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, background_color: bgColor } = primary;

  const titleHtml = title?.html;
  const bgVariant = bgColor === 'Custard' ? styles.custardBg : styles.blueBg;

  const [showModal, setShowModal] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [bodyLocked, setBodyLock] = useState(false); // lock body while modal open

  const openModal = (event, index) => {
    event.preventDefault();
    setShowModalIndex(index);
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModalIndex(null);
    setShowModal(false);
  };

  const lockBody = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(currentScrollTop);
    document.body.style.top = `-${currentScrollTop}px`;
    document.body.classList.add('modal-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.style.top = '0px';
    document.body.classList.remove('modal-open');
    window.scrollTo(0, scrollTop);
    setBodyLock(false);
  };

  // use effect when showmodal updated
  useEffect(() => {
    if (showModal && !bodyLocked) lockBody();
    if (!showModal && bodyLocked) unlockBody();
    return () => {
      unlockBody();
    };
  }, [showModal]);

  const activeItem = items[showModalIndex];

  return (
    <Section
      className={`${styles.section} ${bgVariant}`}
      containerClassName={styles.container}
      sliceName="ModalTeamGrid"
      gradientBg={bgColor === 'Custard'}
    >
      {titleHtml && <Text html={titleHtml} className={styles.title} h2 />}
      <div className={styles.grid}>
        {items.map((item, index) => {
          const { content, image, name, role } = item;
          return (
            <Fragment key={name?.text}>
              <div className={styles.card}>
                <div>
                  <Link to="#openmodal" onClick={(e) => openModal(e, index)}>
                    {image?.url && <Image image={image} className={styles.image} />}
                  </Link>
                </div>
                <div>
                  {name?.text && <Text html={name.html} className={styles.name} h4 />}
                  <Link
                    to="#openmodal"
                    onClick={(e) => openModal(e, index)}
                    className={`cta ${bgColor === 'Custard' ? 'secondary' : ''} ${styles.cta}`}
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <Modal active={showModal} closeModal={closeModal} className={styles.modal}>
                <div className={styles.modalContent}>
                  {activeItem?.image?.url && <Image image={activeItem?.image} className={styles.image} />}
                  <div className={styles.textContainer}>
                    <FilledLogo className={styles.logo} />
                    {activeItem?.content?.text && (
                      <div className={styles.bioContainer}>
                        <Text html={activeItem?.content?.html} className={styles.bio} p1 />
                      </div>
                    )}
                    {activeItem?.cta_link?.url && activeItem?.cta_text?.text && (
                      <Button theme="primary" to={activeItem?.cta_link?.url} className={styles.button}>
                        {activeItem?.cta_text?.text}
                      </Button>
                    )}
                  </div>
                </div>
              </Modal>
            </Fragment>
          );
        })}
      </div>
    </Section>
  );
};
export default ModalTeamGrid;
