import React from 'react';
import { Image } from 'components';
import Star1 from 'images/sprinkles/cross-star-1.inline.svg';
import Star2 from 'images/sprinkles/cross-star-2.inline.svg';
import Star3 from 'images/sprinkles/cross-star-3.inline.svg';
import Star4 from 'images/sprinkles/cross-star-4.inline.svg';
import Star5 from 'images/sprinkles/cross-star-5.inline.svg';
import Star6 from 'images/sprinkles/cross-star-6.inline.svg';

import * as styles from './clippers.module.scss';

const CrossClipper = ({ image, className }) => (
  <div className={`${styles.crossClipper} ${className || ''}`}>
    <Image image={image} className={`${styles.crossImage} ${styles.image}`} />
    <Star1 className={`${styles.star1} ${styles.crossSprinkle}`} />
    <Star2 className={`${styles.star2} ${styles.crossSprinkle}`} />
    <Star3 className={`${styles.star3} ${styles.crossSprinkle}`} />
    <Star4 className={`${styles.star4} ${styles.crossSprinkle}`} />
    <Star5 className={`${styles.star5} ${styles.crossSprinkle}`} />
    <Star6 className={`${styles.star6} ${styles.crossSprinkle}`} />
  </div>
);

export default CrossClipper;
