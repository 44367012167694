module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Revive', // Default Site Title used for SEO & PWA
  description: 'At RevIVe, we believe in the profound impact of holistic health care', // Default Site Decription used for SEO
  siteName: 'Revive', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language
  socialLinks: [
    // 'https://www.instagram.com/Reviveclinic/',
    // 'https://www.facebook.com/ReviveClinic',
    // 'https://www.linkedin.com/company/Revive/',
  ], // Array of social links (facebook, insta, etc)
  // JSONLD / Manifest
  icon: 'src/images/pwa-icon.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'Revive', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Revive', // Author for schemaORGJSONLD
  themeColor: '#29525c', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#f4e7de', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet
  twitter: '', // Twitter Username
};
