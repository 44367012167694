import React from 'react';
import { Section, Text, Link, OnVisible, Image } from 'components';
import LargeLogo from 'images/large-dark-logo.inline.svg';
import VideoClipped from './VideoClipped';
import Button from '../../components/Button';

import * as styles from './styles.module.scss';

const Header = (props) => {
  const { data, isHome } = props;
  const { primary } = data;
  const { content, cta_text: ctaText, cta_link: ctaLink, video, title, image } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const homeVariant = isHome ? styles.homeVariant : '';

  return (
    <Section
      containerClassName={`${styles.container} ${homeVariant}`}
      className={`${styles.section} ${homeVariant}`}
      sliceName="Header"
    >
      <div className={styles.textContainer}>
        {titleHtml && <Text html={titleHtml} className={styles.title} visibleClassName={styles.titleVisible} />}
        {contentHtml && (
          <Text
            html={contentHtml}
            p3={isHome}
            p2={!isHome}
            className={styles.content}
            visibleClassName={styles.contentVisible}
          />
        )}
        {hasCta && (
          <OnVisible className={styles.buttonContainer} visibleClassName={styles.buttonContainerVisible}>
            <Button theme="primary" to={ctaLink.url} className={styles.button}>
              {ctaText.text}
            </Button>
          </OnVisible>
        )}
      </div>
      {/* normal variant */}
      {!video.url && isHome && (
        <OnVisible className={styles.logoContainer} visibleClassName={styles.visibleLogoContainer}>
          <LargeLogo className={styles.bgLogo} />
          {image?.url && <Image image={image} className={styles.headerImage} />}
        </OnVisible>
      )}
      {/* video clip variant */}
      {video?.url && isHome && (
        <div className={styles.videoColumn}>
          <VideoClipped video={video?.url} className={styles.videoLogo} />
        </div>
      )}
    </Section>
  );
};

export default Header;
