import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { FormField, OnVisible } from 'components';
import useDataLayer from '../../hooks/useDataLayer';
import * as styles from './styles.module.scss';

const Form = ({ fields, onSubmit, formSparkId, submitButton, className, footer, sections }) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' });
  const { errors, isSubmitting } = formState;

  const [submissionError, setSubmissionError] = useState(false);
  const [success, setSuccess] = useState(false);
  const sendToDataLayer = useDataLayer();

  const defaultOnSubmit = async (values) => {
    try {
      const url = `https://submit-form.com/${formSparkId || ''}`; // pass formSparkId to send to formSpark
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      const response = await fetch(url, config);
      // const json = await response.json()
      if (response.ok) {
        // return json
        sendToDataLayer('contact_form_submission');
        setSuccess(true);
        navigate('/thank-you');
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
    }
  };

  return (
    <section>
      {submissionError && <p>{submissionError}</p>}
      <form
        onSubmit={handleSubmit(onSubmit || defaultOnSubmit)}
        className={`${styles.form} ${className || ''} ${footer ? styles.footerForm : ''}`}
      >
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        <div className={styles.grid}>
          {fields &&
            fields.map((field) => {
              const hasError = errors[field.name];

              return (
                <Fragment key={field.name}>
                  <OnVisible
                    className={`${styles.field} ${field.className || ''}`}
                    visibleClassName={styles.visibleField}
                  >
                    {field?.label && <span className={styles.label}>{field.label}</span>}
                    <FormField {...field} register={register} className="form-field" />
                    <input
                      {...register('_gotcha')}
                      type="checkbox"
                      name="_gotcha"
                      style={{ display: 'none' }}
                      tabIndex="-1"
                      autoComplete="off"
                    />
                    <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                      {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
                    </div>
                  </OnVisible>
                </Fragment>
              );
            })}
        </div>
        {sections &&
          sections.map((item) => {
            const { title, fields: sectionFields } = item;
            return (
              <div className={styles.formSection}>
                <span className={styles.sectionTitle}>{title}</span>
                <div className={styles.grid}>
                  {sectionFields.map((field) => {
                    const hasError = errors[field.name];

                    return (
                      <Fragment key={field.name}>
                        <OnVisible
                          className={`${styles.field} ${field.className || ''}`}
                          visibleClassName={styles.visibleField}
                        >
                          {field?.label && <span className={styles.label}>{field.label}</span>}
                          <FormField {...field} register={register} className="form-field" />
                          <input
                            {...register('_gotcha')}
                            type="checkbox"
                            name="_gotcha"
                            style={{ display: 'none' }}
                            tabIndex="-1"
                            autoComplete="off"
                          />
                          <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                            {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
                          </div>
                        </OnVisible>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            );
          })}
        <div className={styles.buttonContainer}>
          <div>
            {success && (
              <span className={styles.successMsg}>
                {footer ? 'Thanks for subscribing!' : "Success! We'll be in touch soon"}
              </span>
            )}
          </div>
          <button
            type="submit"
            className={` ${footer ? styles.footerButton : styles.button} ${styles.formButton}`}
            disabled={isSubmitting}
          >
            {isSubmitting ? submitButton?.submissionText || 'Submitting' : submitButton?.defaultText || 'Submit'}
          </button>
        </div>
      </form>
    </section>
  );
};

export default Form;
