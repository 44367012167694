import React, { useEffect, useState } from 'react';
import { Section, Text, Link, Image, OnVisible } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './styles.module.scss';
import useDebounce from '../../hooks/useDebounce';
import Button from '../../components/Button';

const IconsStatsText = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, tagline, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  // state
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [breakpoint, setBreakPoint] = useState(true); // stop floaty-ness @ large-tablet
  const [heightPercent, setHeightPercent] = useState(0);
  const [widthPercent, setWidthPercent] = useState(0);
  const [shapesTransform, setShapesTransform] = useState([]); // move shapes around
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!breakpoint) {
      const xAxis = position.x - width / 10;
      const yAxis = position.y - height / 10;
      const max = 140;
      const xMovement = xAxis >= max ? max : xAxis;
      const yMovement = yAxis >= max ? max : yAxis;

      const updatedShapesTransform = [
        {
          transform: `translate(${xMovement}%, ${yMovement}%)`,
        },
        {
          transform: `translate(${xMovement}%, ${yMovement}%)`,
        },
        {
          transform: `translate(${xMovement}%, ${yMovement}%)`,
        },
        {
          transform: `translate(${xMovement}%, ${yMovement}%)`,
        },
      ];
      setShapesTransform(updatedShapesTransform);
    }
  }, [useDebounce(widthPercent, 75), useDebounce(heightPercent, 75)]);

  // handle resize
  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    if (window.innerWidth <= 760) {
      setBreakPoint(true);
    }
    if (window.innerWidth > 760) {
      setBreakPoint(false);
    }
  };

  // handle mouse move
  const handleMouseMove = (event) => {
    if (!breakpoint) {
      const { clientX, clientY } = event;
      // set mouse pos
      const heightCalc = Math.round((clientY / height) * 100);
      setPosition({ x: clientX, y: clientY });
      if (heightCalc < 0) {
        setHeightPercent(0);
      }
      if (heightCalc > 100) {
        setHeightPercent(100);
      }
      if (heightCalc < 100 && heightCalc > 0) {
        setHeightPercent(heightCalc);
      }
      setWidthPercent(Math.round((clientX / width) * 100));
    }
  };

  // cursor listener
  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    // cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [height, position, width]);

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => handleResize);
    return () => {
      window.removeEventListener('resize', () => handleResize);
    };
  }, []);

  // get and set width on mount
  useEffect(() => {
    setWidth(window.innerWidth);
    if (window.innerWidth <= 760) {
      setBreakPoint(true);
    }
    if (window.innerWidth > 760) {
      setBreakPoint(false);
    }
  }, []);

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="IconsStatsText">
      <div className={styles.textContainer}>
        <OnVisible className={styles.logoContainer} visibleClassName={styles.logoContainerVisible}>
          <StaticImage
            alt="Revive logo"
            className={styles.logo}
            placeholder="NONE"
            src="../../images/logo-icon-color.png"
          />
        </OnVisible>
        {taglineHtml && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
        {titleHtml && <Text html={titleHtml} className={styles.title} h2 />}
        {contentHtml && <Text html={contentHtml} className={styles.content} p2 />}
      </div>
      <div className={styles.gridContainer}>
        {items && items.length > 0 && (
          <div className={styles.grid}>
            {items.map((item, index) => {
              const { icon, property, value } = item;
              return (
                <OnVisible
                  key={property.text}
                  className={styles.column}
                  visibleClassName={styles.columnVisible}
                  style={{ transitionDelay: `${index * 0.2}s` }}
                >
                  {icon?.url && <Image image={icon} className={styles.icon} />}
                  {value?.text && <Text html={value.html} h2 className={styles.columnTitle} />}
                  {property?.text && <Text html={property.html} p1 />}
                  <div
                    className={`${styles.floatyBlob} blob-${index}`}
                    // style={{ ...blobStyle, transform: shapesTransform[index]?.transform || `translate(0%, 0%)` }}
                    // style={{ ...blobStyle, transform: `translate(0%, 0%)` }}
                  />
                </OnVisible>
              );
            })}
          </div>
        )}
        {hasCta && (
          <OnVisible
            className={styles.buttonContainer}
            visibleClassName={styles.buttonContainerVisible}
            style={{ transitionDelay: `${items.length * 0.2}s` }}
          >
            <Button theme="primary" to={ctaLink.url} className={styles.button}>
              {ctaText.text}
            </Button>
          </OnVisible>
        )}
      </div>
    </Section>
  );
};
export default IconsStatsText;
