// extracted by mini-css-extract-plugin
export var crossClipper = "clippers-module--crossClipper--9db64";
export var crossImage = "clippers-module--crossImage--5c237";
export var crossSprinkle = "clippers-module--crossSprinkle--eba59";
export var flashClipper = "clippers-module--flashClipper--92b8a";
export var flashImage = "clippers-module--flashImage--31c56";
export var flashSparkle = "clippers-module--flashSparkle--00e3e";
export var heartClipper = "clippers-module--heartClipper--34c24";
export var heartImage = "clippers-module--heartImage--10b53";
export var heartSprinkle = "clippers-module--heartSprinkle--c7d1a";
export var image = "clippers-module--image--2f797";
export var leftSprinkle = "clippers-module--leftSprinkle--54052";
export var rightSprinkle = "clippers-module--rightSprinkle--16da6";
export var sparkle1 = "clippers-module--sparkle1--b99d5";
export var sparkle2 = "clippers-module--sparkle2--d0646";
export var sparkle3 = "clippers-module--sparkle3--bfadf";
export var sparkle4 = "clippers-module--sparkle4--8e974";
export var sparkle5 = "clippers-module--sparkle5--ab0ef";
export var star1 = "clippers-module--star1--889c0";
export var star2 = "clippers-module--star2--05044";
export var star3 = "clippers-module--star3--70fdc";
export var star4 = "clippers-module--star4--96826";
export var star5 = "clippers-module--star5--6d40a";
export var star6 = "clippers-module--star6--30fea";
export var wobbleRightAndLeft = "clippers-module--wobbleRightAndLeft--7c6aa";