import React, { useState, useEffect } from 'react';
import { Section, Text, Link, Image, OnVisible } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './styles.module.scss';
import Button from '../../components/Button';

const TextBoxBgImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    align_box_right: boxRight,
    background,
    cta_is_button: ctaIsButton,
    include_logo: includeLogo,
    background_image: bgImage,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    tagline,
    title,
  } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  // variants
  const boxAlign = boxRight ? styles.alignBoxRight : '';
  const boxColor = background === 'Custard' ? styles.custardBox : '';
  const ctaType = ctaIsButton ? styles.ctaIsButton : '';
  const isJustImage =
    !ctaIsButton.text &&
    !content.text &&
    !ctaLink.url &&
    !ctaText.text &&
    !tagline.text &&
    !tagline.text &&
    !title.text;
  const justImageVariant = isJustImage ? styles.emptyVariant : '';
  const classNames = `${styles.section} ${boxAlign} ${boxColor} ${ctaType} ${justImageVariant} `;

  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);
  // set breakpoint state
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    if (widthRef < 900) {
      setBreakPoint(true);
    } else {
      setBreakPoint(false);
    }
  }, [width]);

  return (
    <Section
      containerClassName={styles.container}
      className={classNames}
      sliceName="TextBoxBgImage"
      bgImage={breakPoint ? '' : bgImage}
      noContainer={breakPoint}
    >
      {breakPoint && <Image image={bgImage} />}
      <OnVisible className={`${boxColor} ${styles.textBox}`} visibleClassName={styles.textBoxVisible}>
        {includeLogo && (
          <StaticImage
            alt="Revive logo"
            className={styles.logo}
            placeholder="NONE"
            src="../../images/logo-icon-white.png"
          />
        )}
        <OnVisible className={styles.taglineContainer} visibleClassName={styles.taglineVisible}>
          {tagline?.text && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
        </OnVisible>

        {titleHtml && <Text html={titleHtml} h2 className={styles.title} visibleClassName={styles.titleVisible} />}
        {contentHtml && (
          <Text html={contentHtml} p2 className={styles.content} visibleClassName={styles.contentVisible} />
        )}
        {hasCta && (
          <OnVisible className={styles.ctaContainer} visibleClassName={styles.ctaContainerVisible}>
            <Button
              theme={background === 'Custard' ? 'primary' : 'tertiary'}
              to={ctaLink.url}
              className={styles.ctaButton}
            >
              {ctaText.text}
            </Button>
          </OnVisible>
        )}
      </OnVisible>
    </Section>
  );
};
export default TextBoxBgImage;
