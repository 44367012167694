import React from 'react';
import { Image } from 'components';
import LeftSprinkle from 'images/sprinkles/heart-sprinkles-left.inline.svg';
import RightSprinkle from 'images/sprinkles/heart-sprinkles-right.inline.svg';
import * as styles from './clippers.module.scss';

const HeartClipper = ({ image, className }) => (
  <div className={`${styles.heartClipper} ${className || ''}`}>
    <Image image={image} className={`${styles.heartImage} ${styles.image}`} />
    <LeftSprinkle className={`${styles.heartSprinkle} ${styles.leftSprinkle}`} />
    <RightSprinkle className={`${styles.heartSprinkle} ${styles.rightSprinkle}`} />
  </div>
);

export default HeartClipper;
