import React from 'react';
import { Image } from 'components';
import Sparkle1 from 'images/sprinkles/flash-sparkle-1.inline.svg';
import Sparkle2 from 'images/sprinkles/flash-sparkle-2.inline.svg';
import Sparkle3 from 'images/sprinkles/flash-sparkle-3.inline.svg';
import Sparkle4 from 'images/sprinkles/flash-sparkle-4.inline.svg';
import Sparkle5 from 'images/sprinkles/flash-sparkle-5.inline.svg';
import * as styles from './clippers.module.scss';

const FlashClipper = ({ image, className }) => (
  <div className={`${styles.flashClipper} ${className || ''}`}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <clipPath id="flash-mask">
          <path
            d="M86.1193 196.813L86.1115 196.824L86.1038 196.836C83.7796 200.235 80.0217 202.087 75.9289 202.087C74.6604 202.087 73.3842 201.911 72.0959 201.555C66.9122 199.948 63.4865 195.475 63.4865 190.414V132.868C63.4865 129.159 60.015 126.487 56.3716 126.481L14.2069 125.971L14.1948 125.971H14.1827C9.57045 125.971 5.33979 123.407 3.2865 119.585C1.33869 115.867 1.59754 111.367 4.09843 107.795C4.0988 107.795 4.09917 107.794 4.09955 107.794L74.7394 7.29446L74.7436 7.2885C77.7939 2.9152 83.4808 0.957992 88.741 2.54748C93.9365 4.14916 97.3714 8.62715 97.3714 13.6946V71.2409C97.3714 74.9501 100.843 77.6218 104.486 77.6282C104.491 77.6282 104.495 77.6282 104.5 77.6282L146.651 78.1381L146.663 78.1382H146.675C151.312 78.1382 155.525 80.6202 157.567 84.5153L157.567 84.5161C159.518 88.2339 159.266 92.7456 156.854 96.3282L86.1193 196.813Z"
            fill="#153C65"
          />
        </clipPath>
      </g>
    </svg>
    <Image
      image={image}
      className={`${styles.flashImage} ${styles.image}`}
    />
    <Sparkle1 className={`${styles.sparkle1} ${styles.flashSparkle}`} />
    <Sparkle2 className={`${styles.sparkle2} ${styles.flashSparkle}`} />
    <Sparkle3 className={`${styles.sparkle3} ${styles.flashSparkle}`} />
    <Sparkle4 className={`${styles.sparkle4} ${styles.flashSparkle}`} />
    <Sparkle5 className={`${styles.sparkle5} ${styles.flashSparkle}`} />
  </div>
);

export default FlashClipper;
