// extracted by mini-css-extract-plugin
export var alignBoxRight = "styles-module--alignBoxRight--1798a";
export var container = "styles-module--container--2de59";
export var content = "styles-module--content--e67b0";
export var contentVisible = "styles-module--contentVisible--94021";
export var ctaButton = "styles-module--ctaButton--9481a";
export var ctaContainer = "styles-module--ctaContainer--fd4b5";
export var ctaContainerVisible = "styles-module--ctaContainerVisible--a3397";
export var custardBox = "styles-module--custardBox--39c47";
export var emptyVariant = "styles-module--emptyVariant--14c56";
export var logo = "styles-module--logo--cfb7f";
export var section = "styles-module--section--a9389";
export var tagline = "styles-module--tagline--82d81";
export var taglineContainer = "styles-module--taglineContainer--d32b6";
export var taglineVisible = "styles-module--taglineVisible--6f408";
export var textBox = "styles-module--textBox--4f2bf";
export var textBoxVisible = "styles-module--textBoxVisible--659c7";
export var title = "styles-module--title--87f0d";
export var titleVisible = "styles-module--titleVisible--ab7b8";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--ffc98";