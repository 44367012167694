import React from 'react';
import { Section, Text, OnVisible, Link } from 'components';
import HeartClipper from './HeartClipper.jsx';
import FlashClipper from './FlashClipper.jsx';
import CrossClipper from './CrossClipper.jsx';
import * as styles from './styles.module.scss';
import Button from '../../components/Button/index.jsx';

const ThreeColFeatured = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, tagline, title } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="ThreeColFeatured">
      <div className={styles.textContainer}>
        {taglineHtml && (
          <Text
            dangerouslySetInnerHTML={{ __html: taglineHtml }}
            className={styles.tagline}
            visibleClassName={styles.taglineVisible}
          />
        )}
        {titleHtml && <Text html={titleHtml} className={styles.title} visibleClassName={styles.titleVisible} />}
        {contentHtml && <Text html={contentHtml} className={styles.content} visibleClassName={styles.contentVisible} />}
      </div>
      <div className={styles.grid}>
        {items.map((item, index) => {
          const { content: colContent, image: colImage, title: colTitle } = item;
          return (
            <OnVisible className={styles.column} visibleClassName={styles.columnVisible}>
              <div className={styles.graphic}>
                <div className={styles.imageContainer}>
                  {index === 0 && (
                    <FlashClipper image={colImage} className={`${styles.flash} ${styles.clippedImage}`} />
                  )}
                  {index === 1 && (
                    <CrossClipper image={colImage} className={`${styles.cross} ${styles.clippedImage}`} />
                  )}
                  {index === 2 && (
                    <HeartClipper image={colImage} className={`${styles.heart} ${styles.clippedImage}`} />
                  )}
                </div>
              </div>
              <div>
                {colTitle?.html && (
                  <div dangerouslySetInnerHTML={{ __html: colTitle.html }} className={styles.colTitle} />
                )}
                {colContent?.html && <Text html={colContent.html} className={styles.colContent} p1 />}
              </div>
            </OnVisible>
          );
        })}
      </div>
      {hasCta && (
        <Button theme="primary" to={ctaLink.url} className={styles.button}>
          {ctaText.text}
        </Button>
      )}
    </Section>
  );
};
export default ThreeColFeatured;
