import React, { useState } from 'react';
import { Section, Text, OnVisible, Link } from 'components';
import * as styles from './styles.module.scss';
import Button from '../../components/Button';

const Faqs = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { cta_link: ctaLink, cta_text: ctaText, tagline, title } = primary;
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  const [activeIndex, setActiveIndex] = useState([]);

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((prevIndex) => prevIndex !== index);
      }
      return [...prevIndexes, index];
    });
  };

  // add no schema op in prismic

  const escapeDoubleQuotes = (str) => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const schema = `
  <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQsPageFragment",
    "mainEntity": [
      ${items.map(
    (item) => `
        {
          "@type": "Question",
          "name": "${item.question?.text}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${escapeDoubleQuotes(item.answer?.text)}"
          }
        }`
      )}]
    }
    </script>
  `;

  return (
    <Section
      sliceName="Faqs"
      className={`${styles.faqAccordion} faq-accordion`}
      containerClassName={styles.accordionContainer}
    >
      <div>
        <div className={styles.textContainer}>
          {taglineHtml && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
          {titleHtml && <Text html={titleHtml} h2 className={styles.title} />}
        </div>
        <div className={styles.accordionItems}>
          {items.map((item, index) => {
            const { question, answer } = item;
            const questionText = question?.text;
            const answerHtml = answer?.html;
            const isActive = activeIndex.includes(index);

            return (
              <OnVisible
                key={questionText}
                className={`${styles.accordionItem} ${isActive ? styles.active : styles.inactive}`}
                visibleClassName={styles.accordionItemVisible}
                aria-hidden="true"
              >
                <div className={styles.titleContainer} onClick={(event) => handleOnClick(event, index)}>
                  <Text html={question.html} h4 className={styles.question} />
                  <div className={styles.accordionItemToggleContainer}>
                    <div className={styles.accordionItemToggle} />
                  </div>
                </div>
                {answerHtml && <div className={styles.answer} dangerouslySetInnerHTML={{ __html: answerHtml }} />}
              </OnVisible>
            );
          })}
        </div>
        {ctaLink?.url && ctaText?.text && (
          <Button to={ctaLink.url} theme="primary" className={styles.cta}>
            {ctaText.text}
          </Button>
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </Section>
  );
};

export default Faqs;
