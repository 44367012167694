import React, { useState, useEffect, Fragment } from 'react';
import { Link, Logo, OnVisible, Hamburger } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import { use100vh } from 'react-div-100vh';
import LinkedIn from 'images/icons/linkedin.inline.svg';
import Facebook from 'images/icons/facebook.inline.svg';
import Instagram from 'images/icons/instagram.inline.svg';
import classNames from '../../helpers/classNames';
import Button from '../Button';

import * as styles from './styles.module.scss';

const Header = ({ hideNav }) => {
  const [hasScrolled, setHasScrolled] = useState(false); // set pixels to - from top when shownav = false
  const [stickyHeader, setStickyHeader] = useState(false); // set pixels to - from top when shownav = false

  const data = useStaticQuery(graphql`
    query settings {
      ...settingsFragment
    }
  `);

  const {
    prismicSettings: { data: settingsData },
  } = data;

  const {
    header_navigation: navLinks,
    nav_main_cta_link: ctaLink,
    nav_main_cta_text: ctaText,
    secondary_cta: secondCtaText,
    secondary_cta_link: secondCtaLink,
    facebook,
    linkedin,
    instagram,
  } = settingsData;
  const hasCta = ctaLink?.url && ctaText?.text;
  const hasSecondCta = secondCtaLink?.url && secondCtaText?.text;

  // socials data
  const socialLinks = [
    { comp: <LinkedIn className={styles.socialIcon} />, link: linkedin?.url },
    { comp: <Facebook className={styles.socialIcon} />, link: facebook?.url },
    { comp: <Instagram className={styles.socialIcon} />, link: instagram?.url },
  ];

  // scroll logic
  const handleScroll = () => {
    const { scrollY, requestAnimationFrame } = window;
    requestAnimationFrame(() => {
      if (scrollY > 0 && !hasScrolled) setHasScrolled(true);
      if (scrollY >= 1100 && !stickyHeader) setStickyHeader(true);
      if (scrollY === 0 && hasScrolled) setHasScrolled(false);
      if (scrollY < 1100 && stickyHeader) setStickyHeader(false);
    });
  };

  // use effect to listen to scroll
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled, stickyHeader]);

  // Hamburger
  const [active, setActive] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);

  const onClick = (event) => {
    event.preventDefault();
    setActive(!active);
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K
  const handleEscKey = (event) => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setActive(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  useEffect(() => {
    if (active && !bodyLocked) lockBody();
    if (!active && bodyLocked) unlockBody();
  }, [active]);

  // classnames
  const combinedClassNames = classNames(styles.header, {
    [styles.scrolled]: hasScrolled,
    [styles.sticky]: stickyHeader,
    [styles.hamburgerOpen]: active,
  });

  const height = use100vh();

  const hasNav = navLinks?.[0]?.nav_link?.url && !hideNav;

  return (
    <OnVisible
      as="header"
      className={combinedClassNames}
      visibleClassName={styles.headerVisible}
      style={active ? { height } : {}}
    >
      <div className={`container ${styles.container}`}>
        <div className={styles.logoAndHamburger}>
          <Logo className={styles.logo} />
          {hasNav && (
            <div className={styles.hamburgerContainer}>
              <Hamburger onClick={onClick} active={active} className={styles.hamburger} />
            </div>
          )}
        </div>
        {hasNav ? (
          <div className={styles.navContainer}>
            <nav className={styles.nav}>
              {navLinks.map((link) => {
                const { nav_link: to, nav_text: text } = link;
                return (
                  <Fragment key={text.text}>
                    <Link className={`${styles.navLink} ${link.className || ''}`} to={to.url}>
                      {text.text}
                    </Link>
                  </Fragment>
                );
              })}
            </nav>
            <div className={styles.ctaSocials}>
              {hasCta && (
                <Button to={ctaLink.url} className={styles.buttonPrimary} theme="primary">
                  {ctaText.text}
                </Button>
              )}
              {hasSecondCta && (
                <Button to={secondCtaLink.url} className={styles.buttonSecondary} theme="secondary">
                  {secondCtaText.text}
                </Button>
              )}
              {socialLinks?.length > 0 && (
                <div className={styles.socials}>
                  {socialLinks.map((item, index) => {
                    const { comp, link } = item;
                    if (!link) return null;
                    return (
                      <Fragment key={link}>
                        <Link to={link} style={{ transitionDelay: `${index * 0.2}s` }} className={styles.iconContainer}>
                          {comp}
                        </Link>
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {hasCta && (
              <Button to={ctaLink.url} className={styles.buttonPrimary} theme="primary">
                {ctaText.text}
              </Button>
            )}
            {hasSecondCta && (
              <Button to={secondCtaLink.url} className={styles.buttonSecondary} theme="secondary">
                {secondCtaText.text}
              </Button>
            )}
          </>
        )}
      </div>
    </OnVisible>
  );
};

export default Header;
