import React, { useState, useEffect, Fragment } from 'react';
import { Section, Text, Image, Modal } from 'components';
import FilledLogo from 'images/logo-dark.inline.svg';
import Button from 'components/Button';
import Link from 'components/Link';

import * as styles from './styles.module.scss';

const ModalTeamGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, background_color: bgColor } = primary;

  const titleHtml = title?.html;
  const bgVariant = bgColor === 'Custard' ? styles.custardBg : styles.blueBg;

  const [showModal, setShowModal] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [bodyLocked, setBodyLock] = useState(false); // lock body while modal open

  const openModal = (event, index) => {
    event.preventDefault();
    setShowModalIndex(index);
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModalIndex(null);
    setShowModal(false);
  };

  const lockBody = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(currentScrollTop);
    document.body.style.top = `-${currentScrollTop}px`;
    document.body.classList.add('modal-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.style.top = '0px';
    document.body.classList.remove('modal-open');
    window.scrollTo(0, scrollTop);
    setBodyLock(false);
  };

  // use effect when showmodal updated
  useEffect(() => {
    if (showModal && !bodyLocked) lockBody();
    if (!showModal && bodyLocked) unlockBody();
  }, [showModal]);

  const activeItem = items[showModalIndex];

  const Arrow = () => (
    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3925 0.700195C14.2334 0.700237 14.0779 0.74774 13.9459 0.836631C13.8139 0.925522 13.7114 1.05176 13.6515 1.19921C13.5916 1.34666 13.577 1.50862 13.6097 1.66438C13.6423 1.82015 13.7207 1.96263 13.8347 2.07363L16.4691 4.70801L1.21178 4.81403C1.10578 4.81253 1.00052 4.83211 0.90215 4.87164C0.803776 4.91118 0.714239 4.96986 0.638743 5.0443C0.563247 5.11874 0.503298 5.20743 0.46238 5.30524C0.421462 5.40304 0.400391 5.50801 0.400391 5.61403C0.400391 5.72005 0.421462 5.82501 0.46238 5.92282C0.503298 6.02062 0.563247 6.10932 0.638743 6.18376C0.714239 6.25819 0.803776 6.31688 0.90215 6.35641C1.00052 6.39594 1.10578 6.41553 1.21178 6.41403L16.4691 6.30801L13.8347 8.94238C13.758 9.0161 13.6967 9.10439 13.6544 9.2021C13.6122 9.2998 13.5899 9.40495 13.5888 9.51138C13.5877 9.61781 13.6079 9.72339 13.6481 9.82193C13.6883 9.92048 13.7478 10.01 13.8231 10.0853C13.8984 10.1605 13.9879 10.22 14.0864 10.2602C14.185 10.3005 14.2906 10.3206 14.397 10.3196C14.5034 10.3185 14.6086 10.2962 14.7063 10.2539C14.804 10.2117 14.8923 10.1504 14.966 10.0736L18.8957 6.14395C18.9934 6.06949 19.0726 5.97354 19.1273 5.86353C19.1819 5.75351 19.2105 5.6324 19.2108 5.50956C19.2111 5.38672 19.1831 5.26547 19.129 5.15519C19.0749 5.04491 18.9961 4.94857 18.8988 4.87363C18.8972 4.87259 18.8957 4.87154 18.8941 4.87051L14.966 0.942383C14.8914 0.865747 14.8023 0.804831 14.7038 0.763234C14.6053 0.721637 14.4995 0.700202 14.3925 0.700195Z"
        fill="#29525c"
      />
    </svg>
  );

  return (
    <Section
      className={`${styles.section} ${bgVariant}`}
      containerClassName={styles.container}
      sliceName="ModalTeamGrid"
      gradientBg={bgColor === 'Custard'}
    >
      {titleHtml && <Text html={titleHtml} className={styles.title} h2 />}
      <div className={styles.grid}>
        {items.map((item, index) => {
          const { bio, image, name, role } = item;

          return (
            <Fragment key={name?.text}>
              <div className={styles.card}>
                <div>
                  <Link to="#openmodal" onClick={(e) => openModal(e, index)}>
                    {image?.url && <Image image={image} className={styles.image} />}
                  </Link>
                </div>
                <div>
                  {name?.text && <Text html={name.html} className={styles.name} h4 />}
                  {role?.text && <Text html={role.html} className={styles.role} p1 />}
                  <Link to="#openmodal" onClick={(e) => openModal(e, index)} className={styles.cta}>
                    Learn more <Arrow />
                  </Link>
                </div>
              </div>
              <Modal active={showModal} closeModal={closeModal} className={styles.modal}>
                <div className={styles.modalContent}>
                  {activeItem?.image?.url && <Image image={activeItem?.image} className={styles.image} />}
                  <div className={styles.textContainer}>
                    <FilledLogo className={styles.logo} />
                    {activeItem?.name?.text && <Text html={activeItem?.name.html} className={styles.name} h4 />}
                    {activeItem?.role?.text && <Text html={activeItem?.role.html} className={styles.role} p1 />}
                    {activeItem?.credentials?.text && (
                      <Text html={activeItem?.credentials.html} className={styles.credentials} p1 />
                    )}
                    {activeItem?.bio?.text && (
                      <div className={styles.bioContainer}>
                        <Text html={activeItem?.bio?.html} className={styles.bio} p1 />
                      </div>
                    )}
                    {activeItem?.cta_link?.url && activeItem?.cta_text?.text && (
                      <Button theme="primary" to={activeItem?.cta_link?.url} className={styles.button}>
                        {activeItem?.cta_text?.text}
                      </Button>
                    )}
                  </div>
                </div>
              </Modal>
            </Fragment>
          );
        })}
      </div>
    </Section>
  );
};
export default ModalTeamGrid;
